import { faQuestionSquare } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useState } from "react"
import { LikeType } from "../../types"
import Link from "../link"
import Flag from "./flag"
import HtmlTooltip from "./html-tooltip"
import { useFilters } from "../../hooks/use-filters"
import { useLike } from "../../hooks/use-like"
import { getCachedSecret, useUser } from "../../hooks/use-user"

type Props = {
  contentType: LikeType
  target: string
  id?: string
  flags: number[]
  flag_stats: any[]
  green_flags?: number[]
  red_flags?: number[]
  yellow_flags?: number[]
}

const Flags: FunctionComponent<Props> = ({
  contentType,
  target,
  id,
  flags,
  flag_stats,
  green_flags,
  red_flags,
  yellow_flags,
}) => {
  const [greenFlags, setGreenFlags] = useState(green_flags)
  const [redFlags, setRedFlags] = useState(red_flags)
  const [yellowFlags, setYellowFlags] = useState(yellow_flags)
  const { filters, updateFilters } = useFilters()
  const { user, accountState, updateBalance } = useUser()
  const { report } = useLike()

  const markContent = useCallback(
    (flag: number, pro: boolean) => {
      report(
        id,
        target,
        contentType,
        "Smartlike network",
        flag,
        pro,
        user,
        getCachedSecret(),
        updateBalance
      )
    },
    [target, id, contentType, user]
  )

  const onModeration = useCallback(
    (flag: number, pro: boolean) => {
      markContent(flag, pro)

      if (greenFlags && filters.mod != 0) {
        const g = greenFlags ? greenFlags.indexOf(flag) : -1
        const y = yellowFlags ? yellowFlags.indexOf(flag) : -1
        const r = redFlags ? redFlags.indexOf(flag) : -1
        if (greenFlags) {
          if (pro) {
            if (g != -1) {
            } else {
              setGreenFlags(greenFlags => greenFlags.concat([flag]))
              if (y != -1) {
                setYellowFlags(yellowFlags => {
                  let newTags = yellowFlags.slice()
                  newTags.splice(y, 1)
                  return newTags
                })
              } else if (r != -1) {
                setRedFlags(redFlags => {
                  let newTags = redFlags.slice()
                  newTags.splice(r, 1)
                  return newTags
                })
              }
            }
          } else {
            if (r != -1) {
            } else {
              setRedFlags(greenFlags => redFlags.concat([flag]))
              if (y != -1) {
                setYellowFlags(yellowFlags => {
                  let newTags = yellowFlags.slice()
                  newTags.splice(y, 1)
                  return newTags
                })
              } else if (g != -1) {
                setGreenFlags(greenFlags => {
                  let newTags = greenFlags.slice()
                  newTags.splice(r, 1)
                  return newTags
                })
              }
            }
          }
        }
      }

      /*
        if (greenFlags) {
          const index = greenFlags.indexOf(flag)
          console.log(
            "greenFlags " +
              index +
              " " +
              flag +
              " [" +
              greenFlags +
              "] "
          )
          if (index == -1) {
            setGreenFlags(greenFlags => greenFlags.concat([flag]))
            console.log(
              "greenFlags " +
                index +
                " " +
                flag +
                " [" +
                greenFlags +
                "] "
            )
          }
        }
        if (yellowFlags) {
          const index = yellowFlags.indexOf(flag)
          console.log(
            "yellowFlags " + index + " " + flag + " " + yellowFlags
          )
          if (index > -1) {
            setYellowFlags(yellowFlags => {
              let newTags = yellowFlags.slice()
              newTags.splice(index, 1)
              return newTags
            })
          }
        }
      } else {
        if (greenFlags) {
          const index = greenFlags.indexOf(flag)
          console.log(
            "greenFlags " +
              index +
              " " +
              flag +
              " [" +
              greenFlags +
              "] "
          )
          if (index > -1) {
            setGreenFlags(greenFlags => {
              let newTags = greenFlags.slice()
              newTags.splice(index, 1)
              return newTags
            })
          }
        }
        if (yellowFlags) {
          const index = yellowFlags.indexOf(flag)
          console.log(
            "yellowFlags " + index + " " + flag + " " + yellowFlags
          )
          if (index > -1) {
            setYellowFlags(yellowFlags => {
              let newTags = yellowFlags.slice()
              newTags.splice(index, 1)
              return newTags
            })
          }
        }
      }*/
    },
    [greenFlags, setGreenFlags, yellowFlags, setYellowFlags]
  )

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {greenFlags && filters.mod != 0 ? (
        <>
          {greenFlags?.length > 0 && (
            <span className="unchanged">
              {greenFlags.map(x => (
                <Flag
                  key={x}
                  kind={x}
                  stats={flag_stats}
                  onChange={onModeration}
                />
              ))}
            </span>
          )}
          {yellowFlags?.length > 0 && (
            <span className="proposed">
              {yellowFlags.map(x => (
                <Flag
                  key={x}
                  kind={x}
                  stats={flag_stats}
                  onChange={onModeration}
                />
              ))}
            </span>
          )}
          {redFlags?.length > 0 && (
            <span className="disputed">
              {redFlags.map(x => (
                <Flag
                  key={x}
                  kind={x}
                  stats={flag_stats}
                  onChange={onModeration}
                />
              ))}
            </span>
          )}
        </>
      ) : (
        flags &&
        flags.length > 0 && (
          <>
            {flags.map(x => (
              <Flag
                key={x}
                kind={x}
                stats={flag_stats}
                onChange={onModeration}
              />
            ))}
            {flags.length < 5 && (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        marginBottom: "5px",
                      }}
                    >
                      Collaborative moderation
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                      <div style={{ marginBottom: "5px" }}>
                        If you don't want to see certain content, filters can be
                        enabled in your <Link to="/settings">settings</Link>.
                      </div>
                      <div>
                        Moderation reports are coupled with a 1 cent donation to
                        an arbitrator of your choice.
                      </div>
                    </div>
                  </React.Fragment>
                }
                placement="top"
                interactive
              >
                <div className="thumbsUpContainer">
                  <FontAwesomeIcon
                    icon={faQuestionSquare}
                    style={{ verticalAlign: "middle", paddingLeft: "2px" }}
                  />
                </div>
              </HtmlTooltip>
            )}
          </>
        )
      )}

      <style jsx>{`
        .btn {
          width: 80px;
          padding: 5px;
        }
        /*
        .btn:not(:first-child) {
          margin-left: 5px;
        }*/

        .flagContainer {
        }

        .thumbsUpContainer {
          /*transform: rotate(0.1deg);*/
          color: rgb(129, 129, 129);
          padding-left: 10px;
        }
        .thumbsUpContainer:hover {
          cursor: pointer;
          color: blue;
        }
        .unchanged {
          padding: 3px;
          /*border: solid 1px white;*/
          border-radius: 5px;
          background-color: #00ff0e3d;
          display: flex;
        }

        .disputed {
          padding: 3px;
          /*border: solid 1px red;*/
          border-radius: 3px;
          background-color: #ff00002e;
          display: flex;
        }

        .proposed {
          padding: 3px;
          /*border: solid 1px red;*/
          border-radius: 3px;
          background-color: #ffff0085;
          display: flex;
        }

        .thumbsUp {
          z-index: 1;
          margin-top: -3px;
          /*color: rgb(96, 96, 96);*/
          transform: rotate(1deg);
          fill: rgb(129, 129, 129);
          color: rgba(0, 0, 0, 0);
        }
        .thumbsUp:hover {
          color: blue;
        }
      `}</style>
    </div>
  )
}

export default Flags
