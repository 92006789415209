import {
  faAd,
  faAngry,
  faBan,
  faExclamationTriangle,
  faFlag,
  faHandPaper,
  faPrayingHands,
  faSwords,
  faTrashAlt,
  faVenusMars,
  faUsdSquare,
  faUnlink,
  faEye,
  faCopyright,
  faThumbsDown,
  faHandMiddleFinger,
  faImage,
  faUser,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback } from "react"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { useLike } from "../hooks/use-like"
import { LikeType } from "../types"

type Props = {
  id: string
  contentType: LikeType
  recipient: string
  borderColor: string
}

const Moderation: FunctionComponent<Props> = ({
  id,
  contentType,
  recipient,
  borderColor,
}) => {
  const { user, accountState, updateBalance } = useUser()
  const { report } = useLike()

  const markContent = useCallback(
    async (bit: number) => {
      if (accountState < 0) {
        // local - todo: implement
        return
      }
      report(
        id,
        undefined,
        contentType,
        recipient,
        bit,
        true,
        user,
        getCachedSecret(),
        updateBalance
      )
    },
    [id, contentType, recipient, user]
  )

  return (
    <div className="panel">
      <ul>
        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(3)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faFlag} />
            </div>
            <span className="menuLabel">Gov't sponsored</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(2)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faAd} />
            </div>
            <span className="menuLabel">Promotional</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(7)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faHandPaper} />
            </div>
            <span className="menuLabel">Fake likes</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(0)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faExclamationTriangle} />
            </div>
            <span className="menuLabel">Fake</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(5)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faTrashAlt} />
            </div>
            <span className="menuLabel">Spam</span>
          </div>
        </li>

        <li className="chapter">
          <div
            className="menuItem"
            onClick={() => {
              markContent(1)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faVenusMars} />
            </div>
            <span className="menuLabel">Adult content</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(4)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faSwords} />
            </div>
            <span className="menuLabel">Violent</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(8)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faAngry} />
            </div>
            <span className="menuLabel">Offensive</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(6)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faPrayingHands} />
            </div>
            <span className="menuLabel">Religious offense</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(15)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faHandMiddleFinger} />
            </div>
            <span className="menuLabel">Obscene language</span>
          </div>
        </li>

        <li className="chapter">
          <div
            className="menuItem"
            onClick={() => {
              markContent(16)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faUser} />
            </div>
            <span className="menuLabel">Registration wall</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(12)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faUsdSquare} />
            </div>
            <span className="menuLabel">Paywall</span>
          </div>
        </li>

        <li className="chapter">
          <div
            className="menuItem"
            onClick={() => {
              markContent(9)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faUnlink} />
            </div>
            <span className="menuLabel">Broken link</span>
          </div>
        </li>

        <li>
          <div
            className="menuItem"
            onClick={() => {
              markContent(14)
            }}
          >
            <div className="badge">
              <FontAwesomeIcon className="badge" icon={faImage} />
            </div>
            <span className="menuLabel">Bad metadata</span>
          </div>
        </li>

        <div>
          <li className="chapter">
            <div
              className="menuItem"
              onClick={() => {
                markContent(13)
              }}
            >
              <div className="badge">
                <FontAwesomeIcon className="badge" icon={faCopyright} />
              </div>
              <span className="menuLabel">Copyright violation</span>
            </div>
          </li>

          <li>
            <div
              className="menuItem"
              onClick={() => {
                markContent(10)
              }}
            >
              <div className="badge">
                <FontAwesomeIcon className="badge" icon={faBan} />
              </div>
              <span className="menuLabel">Illegal</span>
            </div>
          </li>

          <li>
            <div
              className="menuItem"
              onClick={() => {
                markContent(11)
              }}
            >
              <div className="badge">
                <FontAwesomeIcon className="badge" icon={faEye} />
              </div>
              <span className="menuLabel">Security threat</span>
            </div>
          </li>
        </div>
      </ul>

      <style jsx>{`
      .chapter {
        border-top: solid #fbe4e4;
        border-width: 1px;
      }
      .ellipsis {
        color: rgb(129, 129, 129);
        z-index: 2
      }
      .ellipsis:hover {
        color: blue;
      }
                .panel {
                  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19);
                  border-radius: 5px;
                  background-color: white;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  z-index: 1000;
    
                }
                .menuLabel {
                  display: table-cell;
                  vertical-align: middle;
                }
                .section {
                  margin-left: 10px;
                  margin-top: 10px;
                  font-weight: 500;
                }
                .badge {
                  margin-right: 10px;
                  font-size: 18px;
                  color: rgb(96,96,96);
                  width: 25px;
                }
                .menuItem {
                    padding: 2px 20px;
                    display: table;
                }
                .submenuItem {
                    padding: 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;                    
                }
    
                ul {
                  margin: 0;
                  padding: 0;
                  font-size: 14px;
                  /*color: #747474;*/
                  font-family: "Roboto", sans-serif;
                }
                li {
                  display: flex;
                  /*align-items: center;*/
                }
                li:hover {
                  background-color: ${borderColor};
                  cursor: pointer;
                }
      
                .thumbsUpContainer {
                  /*transform: rotate(0.1deg);*/
                  color: rgb(129, 129, 129);
                }
                .thumbsUpContainer:hover {
                  cursor: pointer;
                  color: blue;
                }
                .thumbsUpContainerActive {
                  /*transform: rotate(1deg);*/
                  color: rgb(129, 129, 129);
                }
                .thumbsUpContainerActive:hover {
                  color: blue,
                }

                .thumbsUp {
                  z-index: 1;
                  margin-top: -3px;
                  /*color: rgb(96, 96, 96);*/
                  transform: rotate(1deg);
                  fill: rgb(129, 129, 129);
                  color: rgba(0, 0, 0, 0);

                  
                }
                .thumbsUp:hover {
                  color: blue;
                }
                .thumbsUpActive {
                  z-index: 1;
                  margin-top: "-3px";
                  color: blue,
                }
                .likeButton {
                }
                .likeButton:hover {
                  cursor: pointer;
                }
                .interactable {
                    position: relative;
                    z-index: 1;
                }
                .label {
                    min-width: 0;
                }
                .image {
                    border-radius: 0;
                }
                header {
                    display: flex;
                    font-size: 0.8em;
                    overflow: hidden;
                }
                header > *:not(:first-child) {
                    margin-left: 1.6em;
                }
                h1,
                .description {
                    margin: 0;
                }
                h1 {
                    font-size: 22px;
                    font-weight: 500;
                    margin-bottom: 0.5em;
                }
                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    font-size: 0;
                }
                .featured-comment {
                    transform: translateY(-2em);
                    margin-bottom: -2em;
                    margin-left: 10%;
                    margin-right: 20%;
                }
                .action-icon {
                    height: 100px;
                    width: 100px;
                    color: red;
                }
                .tag {
                  background-color: rgb(105, 105, 105, 0.1);
                  padding:5px;
                  margin-left:2px;
                  margin-right:2px;
                  border-radius: 5px;
                  font-size: 12px;
                  user-select: none;
                }
                .tag:hover {
                  background-color: rgb(105, 105, 105, 0.3);
                  cursor: pointer;
                }
                .actions {
                  display: table-row;
                  font-size: 14px;
                  color: #111111;
                  font-weight: 400;
                }
                .comments_count {
                  vertical-align: middle;
                  display: table-cell;
                  padding-right: 5px;
                }
                .comments {
                  display: table-cell;
                }
                .like {
                  display: table-cell;
                  vertical-align: middle;
                  margin-left: 15px;
                  font-size: 14px;
                }
                .table { 
                  display: table;
               }
               .table-row {display: table-row; }
               .table-cell {display: table-cell;
                vertical-align: middle; }
                .comment-bubble {
                  font-size: 16px;
                  padding-left:5px;
                  padding-right:10px;
                  color: rgb(96, 96, 96);
                }
                .comment-bubble:hover {
                  color: blue;
                }
                .amount {
                  display: table-cell;
                  vertical-align: middle;
                  font-size: 14px;
                  font-weight: 500;
                  width: 50px;
                  text-align: right;
                }
               }
          `}</style>
    </div>
  )
}

export default Moderation
